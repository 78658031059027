/* index */

:root {

    --primary-50: #3a9cff;
    --primary-100: #348cff;
    --primary-200: #2e7cff;
    --primary-300: #286dff;
    --primary-400: #225dff;
    --primary-500: #1D4ED8;
    --primary-600: #173eac;
    --primary-700: #112e81;
    --primary-800: #0b1f56;
    --primary-900: #050f2b;
  
    --secondary-50: #24ffff;
    --secondary-100: #20ffff;
    --secondary-200: #1cffff;
    --secondary-300: #19ffff;
    --secondary-400: #15dfff;
    --secondary-500: #12BAE6;
    --secondary-600: #0e94b8;
    --secondary-700: #0a6f8a;
    --secondary-800: #074a5c;
    --secondary-900: #03252e;

    --tertiary-50: #ffff16;
    --tertiary-100: #ffff13;
    --tertiary-200: #fffc11;
    --tertiary-300: #ffdd0f;
    --tertiary-400: #ffbd0d;
    --tertiary-500: #f59e0b;
    --tertiary-600: #c47e08;
    --tertiary-700: #935e06;
    --tertiary-800: #623f04;
    --tertiary-900: #311f02;
  
    --success-50: #20ffff;
    --success-100: #1cffe8;
    --success-200: #19ffce;
    --success-300: #16ffb4;
    --success-400: #13de9a;
    --success-500: #10B981;
    --success-600: #0c9467;
    --success-700: #096f4d;
    --success-800: #064a33;
    --success-900: #032519;
  
    --info-50: #76ffff;
    --info-100: #6aeaff;
    --info-200: #5ed0ff;
    --info-300: #52b6ff;
    --info-400: #469cff;
    --info-500: #3b82f6;
    --info-600: #2f68c4;
    --info-700: #234e93;
    --info-800: #173462;
    --info-900: #0b1a31;
  
    --warning-50: #ffff9a;
    --warning-100: #ffff8a;
    --warning-200: #ffff7b;
    --warning-300: #ffff6b;
    --warning-400: #fffd5c;
    --warning-500: #fcd34d;
    --warning-600: #c9a83d;
    --warning-700: #977e2e;
    --warning-800: #64541e;
    --warning-900: #322a0f;
  
    --error-50: #ff8888;
    --error-100: #ff7a7a;
    --error-200: #ff6c6c;
    --error-300: #ff5f5f;
    --error-400: #ff5151;
    --error-500: #EF4444;
    --error-600: #bf3636;
    --error-700: #8f2828;
    --error-800: #5f1b1b;
    --error-900: #2f0d0d;
  
    --neutral-50: #e6e6e6;
    --neutral-100: #cfcfcf;
    --neutral-200: #b8b8b8;
    --neutral-300: #a1a1a1;
    --neutral-400: #8a8a8a;
    --neutral-500: #737373;
    --neutral-600: #5c5c5c;
    --neutral-700: #454545;
    --neutral-800: #2e2e2e;
    --neutral-900: #171717;
  

    --shades-100: #000000;
    --shades-800: #f4f4f4;
    --shades-900: #FFFFFF;




    --primary:      var(--primary-500);
    --secondary:    var(--secondary-500);
    --tertiary:     var(--tertiary-500);
    --success:      var(--success-500);
    --info:         var(--info-500);
    --warning:      var(--warning-500);
    --error:        var(--error-500);
    --neutral:      var(--neutral-500);






    /* font sizes */
    --text-base: 1rem;
    --text-scale: 1.25;

    --t1: calc(var(--text-base) * pow(var(--text-scale), 8));
    --t2: calc(var(--text-base) * pow(var(--text-scale), 6));

    --h1: calc(var(--text-base) * pow(var(--text-scale), 5));
    --h2: calc(var(--text-base) * pow(var(--text-scale), 4));
    --h3: calc(var(--text-base) * pow(var(--text-scale), 3));

    --sub1: calc(var(--text-base) * pow(var(--text-scale), 2));
    --sub2: calc(var(--text-base) * var(--text-scale));

    --text-button: calc(var(--text-base) * var(--text-scale));

    --text-body: var(--text-base);
    --text-label: calc(var(--text-base) / var(--text-scale));
    
    --text-sm: calc(var(--text-base) / pow(var(--text-scale), 2));
    --text-xs: calc(var(--text-base) / pow(var(--text-scale), 3));



    

}



/* DSEIGN SYSTEM FONT SIZE */
.t1{
    font-size: var(--t1);
}

.t2{
    font-size: var(--t2);
}

.h1{
    font-size: var(--h1);
}

.h2{
    font-size: var(--h2);
}

.h3{
    font-size: var(--h3);
}

.sub1{
    font-size: var(--sub1);
}

.sub2{
    font-size: var(--sub2);
}

.text-button{
    font-size: var(--text-button);
}

.text-body{
    font-size: var(--text-body);
}

.text-label{
    font-size: var(--text-label);
}

.text-sm{
    font-size: var(--text-sm);
}

.text-xs{
    font-size: var(--text-xs);
}





/* DESIGN SYSTEM FONT COLOR */
.primary {
    color: var(--primary);
}
.secondary {
    color: var(--secondary);
}
.tertiary {
    color: var(--tertiary);
}
.success {
    color: var(--success);
}
.info {
    color: var(--info);
}
.warning {
    color: var(--warning);
}
.error {
    color: var(--error);
}
.neutral {
    color: var(--neutral);
}










.selected {
    background-color: var(--primary-100);
    border: 1px solid var(--primary-500);
}

.new-div:empty {
    min-height: 64px;
    min-width: 64px;
    height: 100%;
    width: 100%;
}

.new-div:empty::after{
    content: "ADD ELEMENT HERE";
    color: var(--neutral-400);
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

div[name="menu"]:empty::after{
    content: "MENU AREA";
    color: var(--neutral-400);
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}